<template>
  <div>
    <div class="modal fade" id="cav-upload-modal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form @submit.prevent="uploadCAV">
            <div class="modal-body">
              <div class="form-group">
                <label for="">Name</label>
                <h5 class="text-uppercase">
                  {{ form.firstname }} {{ form.middlename }}
                  {{ form.lastname }} (ID: {{ form.id }})
                </h5>
                <small class="form-text text-muted"
                  >Date Issued: {{ form.created_at | dateTime }}</small
                >

                <p class="text-muted small">
                  Processed by: {{ form.processed_by }}
                </p>
              </div>

              <div class="form-group">
                <label for="">SECPA No</label>
                <input
                  type="text"
                  v-model="form.secpa_no"
                  class="form-control"
                />
              </div>

              <div class="form-group">
                <label for="">Mode of Study</label>
                <select
                  name=""
                  id=""
                  class="form-control"
                  v-model="form.study_mode"
                  required
                >
                  <option value="CONVENTIONAL" selected>CONVENTIONAL</option>
                  <option value="CONVENTIONAL AND BLENDED">
                    CONVENTIONAL AND BLENDED
                  </option>

                  <option value="CONVENTIONAL AND ONLINE">
                    CONVENTIONAL AND ONLINE
                  </option>
                  <option value="CONVENTIONAL AND OFFLINE">
                    CONVENTIONAL AND OFFLINE
                  </option>
                  <option value="CONVENTIONAL AND ETEEAP">
                    CONVENTIONAL AND ETEEAP
                  </option>
                  <option value="ONLINE AND BLENDED">ONLINE AND BLENDED</option>
                  <option value="OFFLINE AND BLENDED">
                    OFFLINE AND BLENDED
                  </option>
                  <option value="BLENDED">BLENDED</option>
                  <option value="ONLINE">ONLINE</option>
                  <option value="OFFLINE">OFFLINE</option>
                  <option value="NON-CONVENTIONAL">NON-CONVENTIONAL</option>
                  <option value="ETEEAP">ETEEAP</option>
                  <option value="ETEEAP AND ONLINE">ETEEAP AND ONLINE</option>
                </select>
              </div>

              <div v-if="form.student_type == 'UE'" class="form-group">
                <label for="">Units Earned</label>
                <input
                  type="text"
                  class="form-control text-uppercase"
                  v-model="form.units_earned"
                />
              </div>

              <div class="form-group">
                <label for="">Processed by</label>
                <select
                  name=""
                  id=""
                  class="form-control"
                  v-model="form.processed_by"
                >
                  <option value="Lennie Juezan">LENNIE JUEZAN</option>
                  <option value="Misael Cabang">MISAEL CABANG</option>
                  <option value="Mercedes Rebita">MERCEDES REBITA</option>
                  <option value="Ceaka Angel Pacot">CEAKA ANGEL PACOT</option>
                </select>
              </div>

              <div class="form-group">
                <label for="">Reviewed by</label>
                <select
                  name=""
                  id=""
                  class="form-control"
                  v-model="form.reviewed_by"
                  required
                >
                  <option value="Francis D. Ilagan">FRANCIS D. ILAGAN</option>
                  <option value="Misael S. Cabang">MISAEL S. CABANG</option>
                  <option value="Revelyn P. Brina">REVELYN P. BRINA</option>
                  <option value="Angel A. Asis">ANGEL A. ASIS</option>
                  <option value="Francis D. Ilagan">FRANCIS D. ILAGAN</option>
                  <option value="Geselle M. Francisco">
                    GESELLE M. FRANCISCO
                  </option>
                  <option value="Haemaccel I. Palima">
                    HAEMACCEL I. PALIMA
                  </option>
                  <option value="Ma. Mercedes P. Rebita">
                    MA. MERCEDES P. REBITA
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label for="">Signed by</label>
                <select
                  name=""
                  id=""
                  class="form-control"
                  v-model="form.signed_by"
                  required
                >
                  <option
                    value="GEORGE M. COLORADO, PhD, CESO III : Director IV"
                  >
                    GEORGE M. COLORADO, PhD, CESO III : Director IV
                  </option>
                  <option
                    value="ALMA SY-PATRON, PHD, RSW : OIC, Office of the Regional Director"
                  >
                    ALMA SY-PATRON, PHD, RSW : OIC, Office of the Regional
                    Director
                  </option>
                  <option
                    value="JULIA FELISA C. MARTINEZ, PhD,LPT : Chief Education Program Specialist"
                  >
                    JULIA FELISA C. MARTINEZ, PhD,LPT : Chief Education Program
                    Specialist
                  </option>
                  <option
                    value="NOEME ROSE D. MANI, MM, CPA : SEPS/OIC, Chief Administrative Officer"
                  >
                    NOEME ROSE D. MANI, MM, CPA : SEPS/OIC, Chief Administrative
                    Officer
                  </option>
                  <option
                    value="REVELYN P. BRINA, MAED : Administrative Officer III"
                  >
                    REVELYN P. BRINA, MAED : Administrative Officer III
                  </option>
                  <option value="GESELLE M. FRANCISCO, CPA : Accountant II">
                    GESELLE M. FRANCISCO, CPA : Accountant II
                  </option>
                  <option
                    value="AMY C. SAAVEDRA, MSCA : Education Supervisor II"
                  >
                    AMY C. SAAVEDRA, MSCA : Education Supervisor II
                  </option>
                </select>
              </div>
            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-link" data-dismiss="modal">
                Close
              </button>
              <button type="submit" id="update-cav-btn" class="btn btn-primary">
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      forceShow: false,
      show: false,
      file: "",
      prefix: "https://drive.google.com/file/d/",
      form: new Form({
        id: "",
        firstname: "",
        middlename: "",
        lastname: "",
        secpa_no: "",
        study_mode: "",
        reviewed_by: "",
        signed_by: "",
        processed_by: "",
        path: "",
        filename: "",
        uploaded_at: "",
        units_earned: "",
        student_type: "",
      }),
    };
  },
  methods: {
    openModal(cav) {
      $("#cav-upload-modal").modal("show");
      this.form.fill(cav);
      this.show = false;
      this.file = "";
      this.forceShow = false;
    },
    uploadCAV() {
      let btn = document.getElementById("update-cav-btn");
      btn.disabled = true;
      btn.innerHTML = `<span class="spinner-border spinner-border-sm mr-1"></span> Updating...`;

      let fd = new FormData();

      fd.append("id", this.form.id);
      fd.append("secpa_no", this.form.secpa_no);
      fd.append("study_mode", this.form.study_mode);
      fd.append("reviewed_by", this.form.reviewed_by);
      fd.append("signed_by", this.form.signed_by);
      fd.append("received_by", this.form.received_by);
      fd.append("units_earned", this.form.units_earned);
      fd.append("processed_by", this.form.processed_by);

      axios
        .post("api/cavs/update", fd, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          toastr.success(res.data.msg, "Information");
          btn.innerHTML = "Update";
          btn.disabled = false;

          $("#cav-upload-modal").modal("hide");
          this.form.reset();
          this.$emit("getCavs");
        })
        .catch((err) => {
          console.log(err);
          btn.innerHTML = "Update";
          btn.disabled = false;
          toastr.error("Make sure to attach PDF file only.", "Error");
        });
    },

    getFile(event) {
      this.file = event.target.files[0];
    },
    openUrl(path) {
      const url = this.prefix + path + "/view";
      window.open(url, "_blank");
    },

    showEval(path) {
      path != "" ? (this.show = false) : (this.show = true);
    },
  },
};
</script>
